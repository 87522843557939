body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Circular, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Circular';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/circular/CircularXX-Thin.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Circular';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/circular/CircularXX-Light.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Circular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/circular/CircularXX-Regular.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Circular';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/circular/CircularXX-Book.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Circular';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/circular/CircularXX-Medium.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Circular';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/circular/CircularXX-Bold.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Circular';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/circular/CircularXX-Black.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}


@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Circular';
    font-style: italic;
    font-weight: 200;
    src: url('./assets/fonts/circular/CircularXX-ThinItalic.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Circular';
    font-style: italic;
    font-weight: 300;
    src: url('./assets/fonts/circular/CircularXX-LightItalic.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Circular';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/circular/CircularXX-RegularItalic.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Circular';
    font-style: italic;
    font-weight: 500;
    src: url('./assets/fonts/circular/CircularXX-BookItalic.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Circular';
    font-style: italic;
    font-weight: 600;
    src: url('./assets/fonts/circular/CircularXX-MediumItalic.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Circular';
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/circular/CircularXX-BoldItalic.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Circular';
    font-style: italic;
    font-weight: 800;
    src: url('./assets/fonts/circular/CircularXX-BlackItalic.otf') format('opentype'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.Toastify__toast-container--top-center {
    width: 460px;
}

a {
    color: #0370F3;
    text-decoration: none
}
a:hover {
    color: #025AC4
}
a:active {
    color: #01489E;
    text-decoration: underline;
}
